'use client';
import React, { FC, HTMLAttributes } from 'react';
import {
  FloatingPortal,
  UseFloatingReturn,
  useInteractions,
} from '@floating-ui/react';
import styled, { css } from 'styled-components';
import { UseTooltipProps } from './useTooltip';
import { StyledProps } from '@/types/helpers';

export type BaseTooltipPros = {
  type: 'Dark' | 'Light' | 'Suggestions' | 'HoverBox' | 'Graph';
  content: JSX.Element | string;
  width?: string;
  maxWidth?: string;
  disabled?: boolean;
  style?: Omit<
    React.CSSProperties,
    'position' | 'top' | 'left' | 'pointerEvents'
  >;
  floating: Pick<ReturnType<typeof useInteractions>, 'getFloatingProps'> &
    Pick<UseFloatingReturn<HTMLDivElement>, 'strategy' | 'x' | 'y' | 'refs'>;
} & Pick<UseTooltipProps, 'open'> &
  Pick<
    HTMLAttributes<HTMLDivElement>,
    'className' | 'onMouseOver' | 'onMouseOut'
  >;

type StyledTooltipProps = StyledProps<
  Pick<BaseTooltipPros, 'type' | 'width' | 'maxWidth'>
>;

const StyledTooltip = styled.div<StyledTooltipProps>`
  ${(p) => {
    switch (p.$type) {
      case 'Dark':
        return css`
          ${p.theme.texts.Caption2}
          background-color: ${p.theme.colors.base.Gray1};
          color: ${p.theme.colors.base.Gray5};
          ${p.theme.shadows.Dropdown}
          border-radius: 4px;
        `;
      case 'Graph':
        return css`
          background-color: ${p.theme.colors.base.White1};
          color: ${p.theme.colors.base.Gray1};
          ${p.theme.shadows.Selected}
          border-radius: 6px;
        `;
      default:
        return css`
          ${p.theme.texts.Paragraph2}
          background-color: ${p.theme.colors.base.White1};
          color: ${p.theme.colors.base.Gray1};
          ${p.theme.shadows.Dropdown}
          border-radius: 6px;
        `;
    }
  }};

  padding: 0;

  ${(p) =>
    p.$type === 'Dark' &&
    css`
      padding: 3px 8px;
    `}

  ${(p) =>
    p.$type === 'Light' &&
    css`
      padding: 24px;
    `}

  width: ${(p) => p.$width ?? 'unset'};
  max-width: ${(p) => p.$maxWidth ?? p.$width};
  word-wrap: break-word;
  z-index: 999999999999999;
  display: block;
`;

export const BaseTooltip: FC<BaseTooltipPros> = (props) => {
  const {
    content,
    open,
    disabled,
    type,
    maxWidth,
    width,
    style,
    floating: { getFloatingProps, strategy, x, y, refs },
    ...htmlAttributes
  } = props;
  return (
    <FloatingPortal>
      {open && content && !disabled && (
        <StyledTooltip
          $type={type}
          $maxWidth={maxWidth}
          $width={width}
          {...htmlAttributes}
          {...getFloatingProps({
            ref: refs.setFloating,
            className: 'Tooltip',
            style: {
              position: strategy,
              top: y ?? '',
              left: x ?? '',
              pointerEvents: 'auto',
              ...style,
            },
          })}
        >
          {content}
        </StyledTooltip>
      )}
    </FloatingPortal>
  );
};

import {
  offset,
  flip,
  shift,
  autoUpdate,
  useFloating,
  useInteractions,
  useHover,
  useFocus,
  useRole,
  useDismiss,
  useClick,
  Placement,
} from '@floating-ui/react';

export type UseTooltipProps = {
  position?: Placement;
  onClose?: (ref: React.MutableRefObject<any>) => void;
  onOpen?: (ref: React.MutableRefObject<any>) => void;
  mouseEnterDelay?: number;
  mouseLeaveDelay?: number;
  /** Opens the tooltip when the referenced element is focused for accessibility Default: true */
  focus?: boolean;
  /** Handle the state from outside */
  open?: boolean;
  interactionVariant?: 'hover' | 'click';
};

export const useTooltip = ({
  position = 'top',
  open,
  onOpen,
  onClose,
  mouseEnterDelay = 0,
  mouseLeaveDelay = 0,
  interactionVariant = 'hover',
  focus,
}: UseTooltipProps) => {
  const { refs, context, ...floatingRest } = useFloating<HTMLDivElement>({
    placement: position,
    strategy: 'fixed',
    open,
    onOpenChange: (newOpenState) => {
      if (newOpenState === true) {
        onOpen?.(refs.reference);
      } else {
        onClose?.(refs.reference);
      }
    },
    middleware: [offset(5), flip(), shift({ padding: 8 })],
    whileElementsMounted: autoUpdate,
  });

  const floating = { refs, context, ...floatingRest };

  const interactions = useInteractions([
    useHover(floating.context, {
      delay: {
        open: mouseEnterDelay,
        close: mouseLeaveDelay,
      },
      enabled: interactionVariant === 'hover',
    }),
    useClick(context, {
      enabled: interactionVariant === 'click',
    }),
    useFocus(context, { enabled: focus }),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ]);

  return { floating, interactions };
};
